/* Import Arabic fonts */
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

:root {
  --primary-green: #006633;
  --light-green: #9dc88d;
  --accent-gold: #c5a880;
  --dark-green: #004d26;
  --text-color: #444444;
  --bg-pattern: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239dc88d' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

* {
  box-sizing: border-box;
  direction: rtl;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Tajawal', sans-serif;
  background-color: #ffffff;
  background-image: var(--bg-pattern);
  color: var(--text-color);
  line-height: 1.6;
}

/* Section Title Styles */
.section-title {
  padding-bottom: 30px;
  text-align: center;
}

.section-title h2 {
  font-family: 'Tajawal', sans-serif;
  color: var(--primary-green);
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
}

/* Slick Carousel Styles */
.slick-slider {
  width: 100vw !important;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.slick-list {
  direction: ltr !important;
}

.slick-slide {
  direction: rtl !important;
}

.slick-dots {
  bottom: 20px !important;
  z-index: 1;
}

.slick-dots li button:before {
  color: white !important;
  font-size: 12px !important;
  opacity: 0.8;
}

.slick-dots li.slick-active button:before {
  color: white !important;
  opacity: 1;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.hero-section {
  background: linear-gradient(rgba(0, 102, 51, 0.95), rgba(0, 102, 51, 0.9));
  min-height: 80vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./assets/mosque-pattern.svg');
  background-repeat: repeat;
  opacity: 0.1;
  z-index: 0;
}

.hero-section > * {
  position: relative;
  z-index: 1;
}

.services-section {
  padding: 4rem 2rem;
  background-color: white;
}

.service-card {
  transition: transform 0.3s ease;
  background-color: var(--primary-green);
  color: white;
  padding: 2rem;
  border-radius: 15px;
  text-align: center;
  height: 100%;
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-card h3 {
  color: var(--accent-gold);
  margin-bottom: 1rem;
}

.testimonials-section {
  background-color: var(--light-green);
  padding: 4rem 2rem;
  color: var(--primary-green);
}

.contact-section {
  padding: 4rem 2rem;
  background-color: white;
}

.islamic-pattern {
  background-color: #ffffff;
  background-image: var(--bg-pattern);
  background-attachment: fixed;
}

.section-container {
  padding: 4rem 0;
  position: relative;
}

.section-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(to right, var(--primary-green), var(--light-green));
  opacity: 0.3;
}

.arabic-title {
  font-family: 'Tajawal', sans-serif;
  font-weight: 700;
}

.arabic-content {
  font-family: 'Tajawal', sans-serif;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-green);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--dark-green);
}

.nav-link {
  color: white;
  text-decoration: none;
  margin: 0 1rem;
  transition: color 0.3s ease;
  font-weight: 500;
}

.nav-link:hover {
  color: var(--accent-gold);
}

.custom-button {
  background-color: var(--accent-gold);
  color: var(--primary-green);
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-weight: bold;
  margin: 1rem 0;
}

.custom-button:hover {
  background-color: white;
  color: var(--primary-green);
}

.footer {
  background-color: var(--primary-green);
  color: white;
  padding: 2rem;
  text-align: center;
}

.hero-text {
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
}
